// extracted by mini-css-extract-plugin
export var ContributionImg = "program-module--ContributionImg--M75kU";
export var btn = "program-module--btn--SW0Zo";
export var catchImg = "program-module--catchImg--Or1VX";
export var container = "program-module--container--SYvLa";
export var item = "program-module--item--2UR74";
export var link = "program-module--link--SJLIC";
export var paragraph = "program-module--paragraph--q9j2E";
export var title = "program-module--title--WXsIo";
export var titleBlock = "program-module--titleBlock--oOKJw";
export var typography = "program-module--typography--iim2g";