import * as React from 'react'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Grid } from 'swiper'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as classes from './caseStudies.module.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

type CarouselItem = {
  companyName: string
  contentful_id: string
  logo: { gatsbyImageData: IGatsbyImageData }
  url: string
}

type CaseStudiesCompProps = {
  caseStudyCarousel: CarouselItem[]
}

const CaseStudiesComp = ({ caseStudyCarousel }: CaseStudiesCompProps) => {
  const [carouselItemGroups, setCarouselItemGroups] = useState<
    CarouselItem[][]
  >([])
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([])

  useEffect(() => {
    const newCarouselItemGroups = []
    const allItems = caseStudyCarousel.map(item => ({
      ...item,
      logo: {
        gatsbyImageData: {
          ...item.logo.gatsbyImageData,
          width: 150,
          height: 150,
        },
      },
    }))
    setCarouselItems([...allItems])
    while (allItems.length) {
      const items = allItems.splice(0, 2)
      newCarouselItemGroups.push(items)
    }
    setCarouselItemGroups(newCarouselItemGroups)
  }, [caseStudyCarousel])

  return (
    <section className={classes.caseStudies} id={'cases'}>
      <div className={classes.caseStudiesContent}>
        <h2 className={classes.title}>Case Studies</h2>

        <Swiper
          className={classes.swiper}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          loop={true}
          navigation={true}
          breakpoints={{
            600: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            850: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            1090: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
        >
          {carouselItemGroups.map((group, groupIndex) => (
            <SwiperSlide
              key={'carousel-item-' + groupIndex}
              className={classes.carouselItem}
            >
              {group.map((item, itemIndex) => (
                <a
                  href={item.url}
                  target="_blank"
                  className={classes.carouselSubItem}
                  key={'carousel-subitem-' + itemIndex + groupIndex}
                >
                  <GatsbyImage
                    image={item.logo.gatsbyImageData}
                    alt={'logo'}
                    className={classes.carouselSubItemImage}
                  />
                  <p>{item.companyName}</p>
                </a>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default CaseStudiesComp
