// extracted by mini-css-extract-plugin
export var GridItem = "header-module--GridItem--5Ek4V";
export var SiAnnouncement = "header-module--SiAnnouncement--0jwTS";
export var SiAward = "header-module--SiAward--+PVs9";
export var SiImage = "header-module--SiImage--n7Tv3";
export var btn = "header-module--btn--UkHSc";
export var burgerMenu = "header-module--burgerMenu--kB4bK";
export var burgerMenuItem = "header-module--burgerMenuItem--Scf23";
export var container = "header-module--container--AnMoe";
export var menuItem = "header-module--menuItem--OvYwN";
export var sentence = "header-module--sentence--azE1o";
export var title = "header-module--title--ZfjuE";