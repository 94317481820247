import React, { ChangeEvent, useEffect, useState } from 'react'

import { TextField } from '@mui/material'

const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

type propTypes = {
  inputType: string
  name: string
  label: string
  placeholder: string
  value: string
  rows: number
  multiline: boolean
  showErrors: boolean
  handleChange: Function
  countErrors: Function
}

function InputField({
  inputType,
  name,
  label,
  placeholder,
  value,
  rows,
  multiline,
  showErrors,
  handleChange,
  countErrors,
}: propTypes) {
  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    if (isValid()) {
      countErrors('remove', name)
    } else {
      countErrors('add', name)
    }
  }, [value])

  const isValid = () => {
    if (inputType === 'email' && !emailRegExp.test(value)) {
      setErrorText('Please enter a valid email address.')
      return false
    }

    if (inputType === 'text' && (!value || value.length < 2)) {
      setErrorText('This field should contain at least 2 symbols.')
      return false
    }

    setErrorText('')
    return true
  }

  return (
    <TextField
      id={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleChange(name, e.target.value)
      }
      error={!!errorText && showErrors}
      helperText={showErrors && errorText}
      fullWidth
      rows={rows}
      multiline={multiline}
    />
  )
}

export default InputField
