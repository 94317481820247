import * as React from 'react'
import * as classes from './footer.module.css'
import { useState } from 'react'
import { openInNewTab } from '../../helpers/window-open'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

function Footer() {
  const [imgStatus, setStatus] = useState<boolean>(false)
  const onClickHandler = () => {
    setStatus(!imgStatus)
  }

  return (
    <section className={classes.section}>
      <div className={classes.certificates}>
        <div>
          <img
            src="../../images/SVG/aws-500.png"
            alt="img"
            className={classes.awsImg}
          />
        </div>
        <div>
          <img
            src="../../images/consulting_partner1.png"
            alt="img2"
            className={classes.footerImg}
          />
        </div>
        <div>
          <img
            src="../../images/consulting_partner2.png"
            alt="img2"
            className={classes.footerImg}
          />
        </div>
      </div>
      <div className={classes.footerContainer}>
        <div>
          <Accordion
            expanded={imgStatus}
            onChange={onClickHandler}
            square={true}
            sx={{
              background: 'none',
              boxShadow: 'none',
              '.MuiAccordionSummary-content': {
                margin: '0',
                '&.Mui-expanded': {
                  marginTop: '0',
                },
              },
            }}
          >
            <AccordionSummary
              sx={{ padding: '0', '&.Mui-expanded': { minHeight: '50px' } }}
            >
              <button className={classes.btn}>Impressum</button>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0', marginTop: '1rem' }}>
              <div>
                <img
                  src={'../../images/impressum.png'}
                  alt={'impressum'}
                  height={500}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <div>
            <a
              onClick={() =>
                openInNewTab('https://classmethod.de/cookie-policy.html')
              }
            >
              Cookie Policy
            </a>
            <a onClick={() => openInNewTab('https://classmethod.de/gtm.html')}>
              General Terms and Conditions
            </a>
          </div>
        </div>
        <div>
          <h6>Offices</h6>
          <a onClick={() => openInNewTab('https://classmethod.jp/')}>Japan</a>
          <a onClick={() => openInNewTab('https://www.classmethod.ca/')}>
            Canada
          </a>
        </div>
        <div>
          <h6>Blog</h6>
          <a onClick={() => openInNewTab('https://dev.classmethod.jp/')}>
            Developers.IO
          </a>
          <a
            onClick={() =>
              openInNewTab(
                'https://dev.classmethod.jp/referencecat/classmethod-europe/'
              )
            }
          >
            Classmethod Europe
          </a>
        </div>
        <div>
          <img
            src="../../images/facebook.svg"
            alt="FB-logo"
            onClick={() =>
              openInNewTab('https://www.facebook.com/classmethod.de/')
            }
          />
          <img
            src="../../images/linkedin.svg"
            alt="linkedIn-logo"
            onClick={() =>
              openInNewTab(
                'https://www.linkedin.com/company/classmethodeurope/mycompany/'
              )
            }
          />
        </div>
      </div>
      <p className={classes.copyright}>
        Copyright © Classmethod Inc., and Classmethod (Europe) GmbH
      </p>
    </section>
  )
}

export default Footer
