import * as React from 'react'
import * as classes from './serviceItem.module.css'

type ServiceItemElement = {
  id: number
  title: string
  text: string
  src: string
}

function ServiceItems() {
  const serviceItems: ServiceItemElement[] = [
    {
      id: 1,
      title: 'Cloud Migration',
      text: `We offer secure migration of big data onto trusted Amazon Web Services cloud platforms. 
           So you can deliver content to your customers faster.`,
      src: `../../images/SVG/cloud-migration-icon.svg`,
    },
    {
      id: 2,
      title: 'Consultation',
      text: `We collaborate together with our clients to bring them cost-effective, 
            and applicable AWS products and solutions which best fit the scope of their project.`,
      src: `../../images/SVG/cost-efficiency-icon.svg`,
    },
    {
      id: 3,
      title: 'Maintenance',
      text: `Competent management of your computing resources by our team of Certified Amazon Web Services Experts.`,
      src: `../../images/SVG/maintenance-icon_2.svg`,
    },
  ]

  return (
    <section className={classes.section} id={'service'}>
      <h2 className={classes.title}>Services</h2>
      <div className={classes.container}>
        {serviceItems.map(el => {
          return (
            <div key={el.id} className={classes.item}>
              <img
                src={`${el.src}`}
                alt={el.id.toString()}
                className={classes.itemImage}
              />
              <h3 className={classes.subtitle}>{el.title}</h3>
              <p className={classes.paragraph}>{el.text}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ServiceItems
