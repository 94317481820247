import * as React from 'react'
import { ChangeEvent, useState } from 'react'
import * as classes from './Form.module.css'
import Box from '@mui/material/Box'
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material'
import InputField from './InputField/InputField'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

type FormDetails = {
  firstname: string
  lastname: string
  email: string
  company: string
  country: string
  message: string
}

type HubspotObject = {
  inlineMessage: string;
}

const initialFormDetails: FormDetails = {
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  country: '',
  message: '',
}

type FormField = {
  inputType: string
  name: keyof FormDetails
  label: string
  placeholder: string
  rows: number
  multiline: boolean
  required: boolean
}

const inputFields: FormField[] = [
  {
    inputType: 'text',
    name: 'firstname',
    label: 'First name',
    placeholder: 'Enter your first name',
    rows: 1,
    multiline: false,
    required: true,
  },
  {
    inputType: 'text',
    name: 'lastname',
    label: 'Last name',
    placeholder: 'Enter your last name',
    rows: 1,
    multiline: false,
    required: true,
  },
  {
    inputType: 'text',
    name: 'company',
    label: 'Company name',
    placeholder: 'Enter your company name',
    rows: 1,
    multiline: false,
    required: false,
  },
  {
    inputType: 'text',
    name: 'country',
    label: 'Country',
    placeholder: 'Enter your country',
    rows: 1,
    multiline: false,
    required: true,
  },
  {
    inputType: 'email',
    name: 'email',
    label: 'Email',
    placeholder: 'Enter your email',
    rows: 1,
    multiline: false,
    required: true,
  },
  {
    inputType: 'text',
    name: 'message',
    label: 'Message',
    placeholder: 'Enter your message',
    rows: 4,
    multiline: true,
    required: true,
  },
]

const FormComp = () => {
  const [formDetails, setFormDetails] = useState(initialFormDetails)
  const [errors, setErrors] = useState<string[]>([])
  const [showErrors, setShowErrors] = useState(false)
  const [agreementCheckbox, setAgreementCheckbox] = useState(false)
  const [serverResponse, setServerResponse] = useState<HubspotObject>()

  const payload = {
    "fields":[
      {"objectTypeId": "0-1", "name": "email", "value": formDetails.email ? formDetails.email : ""},
      {"objectTypeId": "0-1", "name": "firstname", "value": formDetails.firstname ? formDetails.firstname : ""},
      {"objectTypeId": "0-1", "name": "lastname", "value": formDetails.lastname ? formDetails.lastname : ""},
      {"objectTypeId": "0-1", "name": "company", "value": formDetails.company ? formDetails.company : ""},
      {"objectTypeId": "0-1", "name": "country", "value": formDetails.country ? formDetails.country : ""},
      {"objectTypeId": "0-1", "name": "message", "value": formDetails.message ? formDetails.message : ""}
  ]}

  const handleChange = (field: string, value: string) => {
    setFormDetails({
      ...formDetails,
      [field]: value,
    })
  }

  const countErrors = (action: string, field: string) => {
    if (action === 'add' && !errors.includes(field)) {
      setErrors([...errors, field])
    }
    if (action === 'remove' && errors.includes(field)) {
      setErrors(errors.filter(error => error !== field))
    }
  }

  const hubspotApiUri = 'https://api.hsforms.com/submissions/v3/integration/submit/' + process.env.GATSBY_HUBSPOT_PORTAL_ID + '/' + process.env.GATSBY_HUBSPOT_FORM_GUID

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (errors.length > 0) {
      setShowErrors(true)
      return
    }
    const response = await window
      .fetch(hubspotApiUri, {
        method: 'POST',
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
      .then(res => res.json())
    setServerResponse(response);
    setShowErrors(false)
    setFormDetails(initialFormDetails)
  }

  return (
    <section className={classes.formSection} id={'contact'}>
      <h2 className={classes.title}>
        Looking for ways to enhance your cloud infrastructure? <br />
        Let us know how we can help
      </h2>
      <GoogleReCaptchaProvider reCaptchaKey="6Ld3xEMhAAAAANOY6q2wQICdp7FCQXA__ikytiR_">
      <form onSubmit={handleSubmit} >
        <div className={classes.formFields}>
          {inputFields.map((inputField, index) => {
            return (
              <InputField
                key={inputField.name + index}
                inputType={inputField.inputType}
                name={inputField.name}
                label={inputField.label}
                placeholder={inputField.placeholder}
                value={formDetails[inputField.name]}
                rows={inputField.rows}
                multiline={inputField.multiline}
                showErrors={showErrors}
                handleChange={handleChange}
                countErrors={countErrors}
              />
            )
          })}
        </div>

        <p className={classes.paragraph}>
          Classmethod is committed to protecting and respecting your privacy, and
          we’ll only use your personal information to administer your account and
          to provide the products and services you requested from us. From time to
          time, we would like to contact you about our products and services, as
          well as other content that may be of interest to you. If you consent to
          us contacting you for this purpose, please tick below to say how you
          would like us to contact you:
        </p>

        <div className={classes.checkboxWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreementCheckbox}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setAgreementCheckbox(e.currentTarget.checked)
                }}
              />
            }
            label="I agree to receive other communications from Classmethod."
          />

          {showErrors && !agreementCheckbox && (
            <FormHelperText error={true}>This field is required.</FormHelperText>
          )}
        </div>

        <p className={classes.paragraph}>
          You may unsubscribe from these communications at any time. For more
          information on how to unsubscribe, our privacy practices, and how we are
          committed to protecting and respecting your privacy, please review our privacy policies(<a href="/gtm.html" target="_blank">General Terms and Conditions</a> and <a href="/cookie-policy.html" target="_blank">Cookie Policy</a>).
        </p>

        <p className={classes.paragraph}>
          By clicking submit below, you consent to allow Classmethod to store and
          process the personal information submitted above to provide you the
          content requested.
        </p>

        <button className={classes.btn}>
          Submit
        </button>
        {serverResponse?.inlineMessage &&
          <div
            className={classes.successMessage}
            dangerouslySetInnerHTML={{ __html: serverResponse.inlineMessage }}
          />
        }
      </form>
      </GoogleReCaptchaProvider>
    </section>
  )
}
export default FormComp
