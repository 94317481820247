import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import HomePage from '../components/Homepage/HomePage'
import ServiceItems from '../components/ServiceItem/serviceItem'
import ExperienceComponent from '../components/ExperienceComponent/ExperienceComponent'
//import MissionComponent from '../components/MissionComponent/MissionComponent'
import AboutComponent from '../components/AboutComponent/AboutComponent'
import CaseStudiesComp from '../components/CaseStudiesComponent/CaseStudiesComp'
import MembersComp from '../components/MembersComp/MembersComp'
import FormComp from '../components/FormComp/FormComp'
import WhatWeAreUpTo from '../components/WhatWeAreUpTo/WhatWeAreUpTo'
import SocialContributionComp from '../components/SocialContributionProgram/SocialContributionComp'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import './normalize.css'
import './styles.css'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="" />
      <Header />
      <HomePage />
      <ServiceItems />
      <ExperienceComponent
        customerExperiences={data.allContentfulCustomerExperienceTop.edges}
      />
      {/*<MissionComponent />*/}
      <AboutComponent />
      <CaseStudiesComp
        caseStudyCarousel={data.contentfulCaseStudyCarousel.casestudies}
      />
      <MembersComp />
      <FormComp />
      <WhatWeAreUpTo />
      <SocialContributionComp rssPosts={data.allRestApiProdTop && data.allRestApiProdTop.edges} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query QueryTop {
    allContentfulCustomerExperienceTop: allContentfulCustomerExperience(
      limit: 30
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          companyName
          url
          logo {
            gatsbyImageData
          }
          service
          quotedMessage {
            quotedMessage
          }
        }
      }
    }
    allRestApiProdTop: allRestApiProd(
      sort: { fields: published, order: DESC }
    ) {
      edges {
        node {
          title
          url
          published
          thumbnail
        }
      }
    }
    contentfulCaseStudyCarousel {
      casestudies {
        companyName
        contentful_id
        url
        logo {
          gatsbyImageData
        }
      }
    }
  }
`

export default IndexPage
