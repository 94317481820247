import * as React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import * as classes from './header.module.css'

type SiPartnerAnnouncement = {
  id: number
  title: string
  text: string
  src: string
}

function Header() {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  )
  const burgerMenuOpened = Boolean(menuAnchorEl)
  const onMenuButtonClickHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setMenuAnchorEl(null)
  }

  const anchorLinksData = [
    {
      path: '#service',
      title: 'Services',
      id: '0001',
    },
    {
      path: '#members',
      title: 'Members',
      id: '0002',
    },
    {
      path: '#cases',
      title: 'Case Studies',
      id: '0003',
    },
    {
      path: '#about',
      title: 'About Us',
      id: '0004',
    },
    {
      path: '#contact',
      title: 'Contact Us',
      id: '0005',
    },
  ]

  const SiPartner: SiPartnerAnnouncement[] = [
    {
      id: 1,
      title: 'Classmethod is the Top AWS System Integrator Partner for 2022',
      text: `Classmethod has been awarded "SI Partner of the Year” as a Top Global AWS Partner for 2022.`,
      src: `../../images/SI_PY_AWS.jpg`,
    }
  ]

  return (
    <> 
    <div className={classes.container}>
      <div>
        <img src="../../images/SVG/logo_black_2023.svg" alt="logo" width={190} />
      </div>
      <nav>
        {anchorLinksData.map(el => {
          return (
            <AnchorLink
              to={el.path}
              title={el.title}
              className={classes.menuItem}
              key={'link' + el.id + el.path}
            />
          )
        })}

        <div className={classes.burgerMenu}>
          <IconButton
            aria-label="delete"
            aria-controls={burgerMenuOpened ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={burgerMenuOpened ? 'true' : undefined}
            onClick={onMenuButtonClickHandler}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={menuAnchorEl}
            open={burgerMenuOpened}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {anchorLinksData.map(el => (
              <MenuItem
                onClick={handleClose}
                key={'menuItem' + el.id}
              >
                <AnchorLink
                  to={el.path}
                  title={el.title}
                  className={classes.burgerMenuItem}
                />
              </MenuItem>
            ))}
          </Menu>
        </div>
      </nav>
    </div>
    </>
  )
}

export default Header
