import Img from 'gatsby-image'
import * as React from 'react'
import * as classes from './experience.module.css'
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";
import {useEffect, useState} from "react";

type CustomerExperience = {
  companyName: string
  logo: {
    gatsbyImageData: IGatsbyImageData
  }
  quotedMessage: { quotedMessage: string }
  service: string
  url: string
}

type ExperienceComponentProps = {
  customerExperiences: { node: CustomerExperience }[]
}

function ExperienceComponent({
  customerExperiences,
}: ExperienceComponentProps) {
  const [customerExperienceItems, setCustomerExperienceItems] = useState<
    CustomerExperience[]
  >([])

  useEffect(() => {
    const items = customerExperiences.map(item => ({
      ...item.node,
      logo: {
        gatsbyImageData: {
          ...item.node.logo.gatsbyImageData,
          width: 200,
          height: 200,
        },
      },
    }))
    setCustomerExperienceItems(items)
  }, [customerExperiences])
  return (
    <section className={classes.section}>
      <h2 className={classes.title}>Customer Experiences</h2>
      <div className={classes.container}>
        {customerExperienceItems.length &&
          customerExperienceItems.map((c) => {
            return (
              <div className={classes.item} key={c.companyName}>
                <div className={classes.companyImg}>{c.logo && <GatsbyImage alt="" image={c.logo.gatsbyImageData}/>}</div>
                <h3 className={classes.subtitle}>{c.companyName}</h3>
                <div>
                  <q>
                    {c.quotedMessage.quotedMessage}              
                  </q>
                </div>
                <p className={classes.paragraph}>{c.service}</p>
                <div>
                  <a
                    href={c.url}
                    target="_blank"
                    aria-label="Read more about How Classmethod helped Japan Tobacco with Amazon Redshift"
                    rel="noreferrer noopener"
                  >
                    <button className={classes.btn}>Learn More</button>
                  </a>{' '}
                </div>
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default ExperienceComponent
