import * as React from 'react'
import * as classes from './homePage.module.css'

function HomePage() {
  return (
    <section className={classes.container}>
      <div className={classes.typography}>
        <div className={classes.typographyInner}>
          <h1 className={classes.title}>
            Reduce Costs, Reach your Customers Faster and Build Securely
          </h1>
          <p className={classes.text}>
            Our global team of certified cloud experts will help you get there
          </p>
          <div className={classes.logoCubeWrapper}>
            <img
              src="../../images/logo_black_2023.png"
              alt={`SocialContributionImg`}
              width={225}
            />
          </div>
        </div>
      </div>
      <div className={classes.homeSectionImg}>
        <img
          src={`../../images/cloud-edited.png`}
          alt="imgCloudEdit"
          className={classes.cloudImage}
        />
      </div>
    </section>
  )
}

export default HomePage
