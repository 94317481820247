import * as React from 'react'
import * as classes from './About.module.css'
import { openInNewTab } from '../../helpers/window-open'

const AboutComponent = () => {
  return (
    <section className={classes.section} id={'about'}>
      <h2 className={classes.title}>
        Why we are Japan's Leading Cloud Integrator
      </h2>
      <div className={classes.aboutContainer}>
        <div className={classes.item}>
          <p className={classes.number}>18,000</p>
          <p className={classes.paragraph}>
            Active AWS accounts <br />
            provided for 700+ companies
          </p>
        </div>
        <div className={classes.item}>
          <p className={classes.number}>2,000</p>
          <p className={classes.paragraph}>
            Certified AWS Professionals <br /> currently employed
          </p>
        </div>
      </div>
      <button
        className={classes.btn}
        onClick={() =>
          openInNewTab(
            'https://classmethod.jp/company/english/company-overview/'
          )
        }
      >
        Go to full company overview
      </button>
    </section>
  )
}

export default AboutComponent
