import * as React from 'react'
import * as classes from './WhatWeAreUpTo.module.css'
import { openInNewTab } from '../../helpers/window-open'

const WhatWeAreUpTo = () => {
  return (
    <section className={classes.section}>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionText}>
          <p className={classes.title}>What we're up to</p>
          <p className={classes.paragraph}>
            Clean-room reverse engineering of Amazon Go. Classmethod's AWS
            engineers made the first prototype within 3 weeks of our CEO
            visiting the store in Seattle. It is evolving towards a real outlet
            in Tokyo.
          </p>
        </div>
        <div className={classes.containerForIframe}>
          <iframe
            className={classes.responsiveIframe}
            src="https://www.youtube.com/embed/t3eE9IOOVHQ"
            width={500}
            height={350}
          ></iframe>
        </div>
      </div>

      <button
        className={classes.btn}
        onClick={() =>
          openInNewTab(
            'https://dev.classmethod.jp/articles/how-cm-created-yokota-de-go/'
          )
        }
      >
        Read More About "Yokota de Go" on Our Blog
      </button>
    </section>
  )
}
export default WhatWeAreUpTo
