import * as React from 'react'
import * as classes from './Members.module.css'
import { openInNewTab } from '../../helpers/window-open'

function MembersComp() {
  return (
    <section className={classes.section} id={'members'}>
      <img
        src={`../../images/membersImg.svg`}
        alt={'membersImg'}
        className={classes.sectionImg}
      />
      <p className={classes.paragraph}>
        “Classmethod Members” is our AWS total support service.<br />
        We are an AWS Premier Consulting Partner providing end-to-end solutions which include consultation, migration, 24/7 maintenance, and VPN services between corporate data centers and AWS. Companies of all sizes have benefited from our expertise in data center migration, DevOps support, development of big data analytics platforms, mobile apps, and Alexa Skills.
      </p>
      <button
        className={classes.btn}
        onClick={() =>
          openInNewTab('https://classmethod.de/members-application.html')
        }
      >
        Become a Member
      </button>
    </section>
  )
}
export default MembersComp
